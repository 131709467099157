<template>
  <b-modal
    id="new-market-type-modal"
    ref="new-market-type-modal"
    v-model="show"
    title="Create New Type Of Market"
    size="md"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <ValidationObserver
      ref="newMarketType"
      v-slot="{ passes }"
    >
      <form @submit.prevent="passes(handleAddNewMarketTYpe)">
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            vid="name"
            name="Market Type"
          >
            <b-form-input
              v-model="name"
              placeholder="Enter Market Type"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <b-button
          variant="primary"
          class="float-right mt-1 w-100"
          type="submit"
          :disabled="isLoading"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-50 p-0"
            small
          />
          <feather-icon
            v-else
            icon="SaveIcon"
            class="mr-50"
          />
          Add New Type Of Market
        </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from '@axios'

export default {
  name: 'CreateNewMarketTypeModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
      name: '',
    }
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.name = ''
      this.$emit('reset-modal')
    },
    async handleAddNewMarketTYpe() {
      try {
        this.isLoading = true
        const response = await axios.post('/markets', { name: this.name })
        if (response.status === 422) {
          this.$refs.newMarketType.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.$swal({
          icon: 'success',
          title: 'New Market Type Added',
          text: 'Successfully Added New Market Type',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.handleHiddenModal()
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>
