<template>
  <b-modal
    id="edit-market-modal"
    ref="edit-market-modal"
    v-model="show"
    title="Edit Market"
    size="lg"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <ValidationObserver
      ref="editMarketForm"
      v-slot="{ passes }"
    >
      <form @submit.prevent="passes(handleEditMarket)">
        <MarketForm :form="form" />
        <b-button
          variant="primary"
          class="float-right mt-1"
          type="submit"
          :disabled="isLoading"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-50 p-0"
            small
          />
          <feather-icon
            v-else
            icon="SaveIcon"
            class="mr-50"
          />
          Edit Market
        </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import MarketForm from '@/components/MarketForm.vue'
import axios from '@axios'

export default {
  name: 'EditMarketModal',
  components: {
    ValidationObserver,
    MarketForm,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
    }
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.$emit('reset-modal')
    },
    async handleEditMarket() {
      try {
        this.isLoading = true
        const response = await axios.patch(`/clients/${this.form.id}`, this.form)
        if (response.status === 422) {
          this.$refs.editMarketForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.$swal({
          icon: 'success',
          title: 'Updated',
          text: 'Successfully Updated The Market',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.$emit('add-success')
          this.handleHiddenModal()
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
