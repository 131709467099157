<template>
  <b-modal
    id="new-market-modal"
    ref="new-market-modal"
    v-model="show"
    :title="title"
    size="lg"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <ValidationObserver
      ref="newMarketForm"
      v-slot="{ passes }"
    >
      <form @submit.prevent="passes(handleAddNewMarket)">
        <MarketForm :form="form" />
        <hr>
        <BranchForm :form="form" />
        <b-button
          variant="primary"
          class="float-right mt-1"
          type="submit"
          :disabled="isLoading"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-50 p-0"
            small
          />
          <feather-icon
            v-else
            icon="SaveIcon"
            class="mr-50"
          />
          Add New Market
        </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BranchForm from '@/components/BranchForm.vue'
import MarketForm from '@/components/MarketForm.vue'
import axios from '@axios'

export default {
  name: 'AddNewMarketModal',
  components: {
    ValidationObserver,
    BranchForm,
    MarketForm,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Add New Market',
    },
  },
  data() {
    return {
      show: false,
      form: {
        client: {},
        branch: {},
      },
      isLoading: false,
    }
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.form = {
        client: {},
        branch: {},
      }
      this.$emit('reset-modal')
    },
    async handleAddNewMarket() {
      try {
        this.isLoading = true
        const response = await axios.post('/clients', this.form)
        if (response.status === 422) {
          this.$refs.newMarketForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.$swal({
          icon: 'success',
          title: 'New Market Added',
          text: 'Successfully Added New Market',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.$emit('add-success')
          this.handleHiddenModal()
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
